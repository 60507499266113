@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.ath-barometer {
  background-color: rgba(0,0,0,.25);
  padding: 1rem 2rem 1rem 1rem;
  border-radius: .5rem;
  flex: 1 1 15rem;
  display: flex;
  justify-content: space-between;
  @include addInnerShadow(rgba(black,.35), 20px);
  @media screen and (max-width: 800px){
    margin: 0 .5rem;
  }
  h3 {
    margin: 0;
    font-weight: normal;
    font-size: .8em;
    opacity: .6;
  }
  .value {
    display: flex;
    align-items: center;
    .current-value {
      font-size: 2em;
      margin: 0;
    }
    .change-value {
      margin-left: .75rem;
      font-size: 1.25em;
      .counter-suffix {
        opacity: .6;
      }
      &.positive {
        color: $positive;
      }
      &.negative {
        color: $negative;
      }
      &.golden {
        color: $goldenYellow;
      }
      &.old {
        opacity: .35;
      }
    }
  }
  .ath-date {
    font-size: .8em;
    opacity: .6;
    margin-left: .5em;
    margin-top: .25em;
    .prefix {
      font-size: .8em;
      opacity: .6;
      margin-right: .25em;
    }
  }
  .graphic {
    text-align: right;
  }
}