@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-urls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  @media screen and (max-width: 800px) {
    gap: .5rem;
    margin: .5rem .5rem 0 .5rem;
    a {
      flex: 1 1 16rem;
    }
  }

  a {
    position: relative;
    padding: 1rem 1.5rem;
    background: linear-gradient(to bottom, darken($documentBackground, 2%), darken($documentBackground, 7%));
    border-radius: .5rem;
    overflow: hidden;
    color: white;

    .mdi-icon {
      scale: 150%;
      margin-right: .25rem;
    }

    &:hover {
      scale: 110%;
      color: $goldenYellow;
    }
  }

  .new-link-button {
    //display: none;
    padding: 1rem 1.5rem;
    background: linear-gradient(to bottom, darken($documentBackground, 2%), darken($documentBackground, 7%));

    .mdi-icon {
      margin: 0;
      scale: 175%;
    }

    &:hover {
      background: linear-gradient(to bottom, darken($documentBackground, 1%), darken($documentBackground, 5%));
      .mdi-icon {
        fill: white;
      }
    }
  }
}