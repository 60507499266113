@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.dog-chart {
  position: relative;
  background: linear-gradient(to top, rgba(black, 15%), rgba(black, 5%));
  border-radius: .5rem;
  margin-top: 1rem;
  height: 50rem;

  &.is-hidden {
    display: none;
  }

  .bg-stripes {
    display: flex;
    flex-direction: column;

    .stripe {
      flex-basis: calc(50rem / 24);
      width: 100%;

      &:nth-child(odd) {
        background-color: rgba(black, 10%);
      }

      &:first-child {
        border-radius: .5rem .5rem 0 0;
      }
    }
  }


  .legend {
    top: 0;
    position: absolute;
    height: 50rem;

    .tick {
      position: absolute;
      left: -1.5rem;
      padding-top: .57rem;
      color: mix($documentBackground, white, 75%);
      text-align: right;
      width: 1rem;
      font-size: .8em;
      font-weight: bold;
      @media screen and (max-width: 800px) {
        left: -1rem;
      }

      .weak {
        opacity: 35%;
      }
    }
  }

  .toggles {
    z-index: 1;
    position: absolute;
    top: 1.03rem;
    width: 100%;
    text-align: center;

    .toggle {
      gap: 0;

      .switch {
        scale: 65%;
      }

      .label {
        margin-left: -.25rem;
        margin-right: .5rem;
      }
    }
  }

  .highlight {
    z-index: 1;
    position: absolute;
    top: 3.53rem;
    width: 100%;
    text-align: center;

    &-content {
      display: inline-flex;
      padding: .35rem .75rem;
      border-radius: .5rem;
      background-color: darken($documentBackground, 2%);
      transition: .25s ease-out;
      @include addShadow();

      .mdi-icon {
        scale: 120%;
        transform: translateY(.15em);
      }

      .date {
        font-size: .75em;
      }

      &.is-hidden {
        opacity: 0;
        transform: translateY(-.5rem);
      }

      &.is-visible {

      }
    }
  }

  .dates {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 0 .5rem;
    height: 100%;
    width: 100%;
    top: 0;

    .date {
      width: 1rem;
      position: relative;

      &.is-today {
        background: linear-gradient(to bottom, rgba($yellow, 0%), rgba($orange, 5%), rgba($yellow, 0%));
      }

      .event {
        position: absolute;
        margin-top: -.7rem;
        margin-left: -.25rem;

        .mdi-icon {
          transition: .25s ease-out;
        }

        &:hover {
          .PEE, .POOP, .FOOD, .ACCIDENT {
            opacity: 100%;
            color: white;
          }
        }
      }
    }
  }

  .POOP {
    color: #865a33;
    opacity: 75%;
  }

  .PEE {
    color: $goldenYellow;
    opacity: 75%;
  }

  .FOOD {
    color: $goldenRed;
    opacity: 50%;
  }

  .ACCIDENT {
    color: $negative;
    opacity: 75%;
  }

  .CLOCK {
    color: $negative;
    opacity: 75%;
    scale: 75%;
  }
}