@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.dawg {
  h2 {
    margin: 0;
    padding: 1rem 0;
  }
  margin: 0 auto 0 auto;
  max-width: 1130px;
  @media screen and (max-width: 800px){
    margin: 0 1rem 1rem 1rem;
  }
  .time-ago {
    gap: .25em;
  }
  .form {
    section {
      margin: 1rem 0;
    }
    .action, .location {
      .button {
        border-radius: 0;
        padding: .75rem 1rem .75rem .75rem;
        &:first-child {
          padding-left: .75rem;
          border-radius: .5rem 0 0 .5rem;
        }
        &:last-child {
          padding-right: 1rem;
          border-radius: 0 .5rem .5rem 0;
        }
      }
    }
    .now-button {
      padding-right: .75rem;
      .mdi-icon {
        margin: 0;
      }
    }
    textarea {
      width: 100%;
      height: 10rem;
    }
    .confirmation {
      justify-content: left;
      margin-bottom: 3rem;
      @media screen and (max-width: 800px){
        margin: 1rem 0 2rem 0;
        justify-content: center;
      }
      .cancel-button {
        .mdi-icon {
          fill: $red;
        }
      }
    }
  }

  .kpis {
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: .5rem;
    .kpi {
      flex: 10rem 1 1;
      padding: .8rem 1.1rem;
      h3 {
        margin-bottom: .06rem;
        .mdi-icon {
          @include md-icon-size(1.2em);
        }
        .flex-vertical-center {
          gap: 0;
        }
      }
      .value {
        font-size: 1.5rem;
      }
      .percentage {
        font-size: .8em;
        opacity: 45%;
        .counter-suffix {
          opacity: 60%;
        }
      }
      .suffix {
        opacity: 65%;
      }
      .median {
        display: flex;
        align-items: flex-end;
        gap: .25em;
        margin-top: .17rem;
        opacity: 50%;
        .label {
          opacity: 50%;
          font-size: .75em;
          padding-bottom: .1em;
        }
      }
      &.dynamic-display {
        @media screen and (max-width: 800px) {
          display: none;
          &.is-negative, &.is-error {
            display: block;
          }
        }
      }
    }
  }

  .sushi-log {
    .date-header {
      padding: 2rem 1rem 1rem 1rem;
      font-size: 1.2em;
      background-color: transparent;
      .wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 1rem;
      }
      .date {
        display: flex;
        align-items: flex-end;
        gap: 1rem;
        .weak {
          font-size: 1rem;
          opacity: 50%;
        }
      }
      .stats {
        display: flex;
        font-size: 1rem;
        margin-left: -.5rem;
        gap: .25rem;
        .stat {
          display: flex;
          justify-content: center;
          .mdi-icon {
            @include md-icon-size(1.05em);
            margin-right: .25em;
          }
        }
      }
    }
    .comment {
      color: mix(white, $documentBackground, 65%);
    }
    td {
      .comment {
      }
      &.time-diff, .time-diff {
        .suffix {
          opacity: 50%;
        }
      }
    }
    .mobile-log-entry {
      border-radius: .35rem;
      .secondary {
        font-size: .8em;
        opacity: 75%;
      }
      .flex-vertical-center {
        justify-content: space-between;
        &.left {
          justify-content: flex-start;
        }
        &.right {
          justify-content: flex-end;
        }
      }
      .header {
        justify-content: space-between;
      }
      .comment {
        position: relative;
        font-size: .8em;
        margin: .75em 0 .25em 0;
        padding: .75em 1em;
        border-radius: .5em;
        background-color: mix(white, $documentBackground, 3%);
        @include addShadow();
        &:before {
          position: absolute;
          top: -.35rem;
          left: 2.2rem;
          rotate: 45deg;
          content: '';
          width: .75rem;
          height: .7rem;
          background-color: mix(white, $documentBackground, 3%);
        }
      }
    }
  }

}