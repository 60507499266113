.ui-wizard {
  position: relative;
  margin-bottom: 5rem;

  &__navigation {
    display: flex;
    justify-content: center;
    //position: sticky;
    //bottom: 0.5rem;
    margin: 1rem 0 0.5rem 0;
    text-align: center;
    gap: 1em;

    .buttons {
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(1px);
      display: flex;
    }

    @media screen and (max-width: 500px) {
      bottom: 2.5rem;
    }

    &.is-big-top-margin {
      margin-top: 3rem;
    }
  }
}