.setup {
  display: none;
  position: absolute;
  margin: 1rem;
  padding: 1rem;
  background-color: rgba(0,0,0,.5);
  border-radius: .5rem;
  .step {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
}