@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.step-select-asset {
  .center-arrow {
    margin: .5rem 0;
    text-align: center;
    .mdi-icon {
      @include md-icon-size(1.5em);
      fill: url(#golden-gradient) $goldenYellow;
    }
  }
}