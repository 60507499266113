@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-icon {
  &.is-crypto {
    color: $goldenRed;
  }
  &.is-stock {
    color: $goldenRed;
  }
  &.is-fund {
    color: $goldenYellow;
  }
  &.is-real-estate {
    color: $goldenYellow;
  }
}