@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.app-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 25vh;
  .loader-circle {
    height: 8rem;
    width: 8rem;
    border-width: .5rem;
    box-shadow: inset 0 0 6px black, 0 0 6px 6px rgba(0,0,0,.25);
  }
  .error {
    margin: 2rem auto;
    max-width: 500px;
    background-color: rgba(black, .25);
    border-radius: 1rem;
    padding: 1rem 2rem;
    @include addInnerShadow(rgba(black, .5), 20px);
    @media screen and (max-width: 500px) {
      max-width: 90vw;
    }
    .__json-pretty__ {
      text-align: left;
      background-color: transparent;
      padding: 0;
    }
  }
}