.add-transaction-bulk {
  h3 {
    font-weight: normal;
    opacity: .5;
  }
  textarea {
    width: 100%;
    height: 20rem;
  }
  .options {
    margin-top: 1rem;
    gap: 1rem;
  }
  .import-report {
    tr {
      cursor: pointer;
      td:first-child {
        width: 1rem;
      }
    }
  }
}