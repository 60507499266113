$size: 4rem;

.countdown-chart {
  position: relative;
  height: $size;
  width: 5rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px){
    height: 4.35rem;
    transform: scale(0.7)
  }
  .canvas {
    position: absolute;
    top: -1.85rem;
    left: -1.45rem;
    height: #{$size * 2};
    width: #{$size * 2};
    @media screen and (max-width: 500px){
      top: -1.6rem;
    }
  }
  .percentage {
    text-align: center;
    margin-top: .3rem;
    margin-left: .3rem;
    left: 0;
    width: 100%;
    font-size: 2rem;
    color: #888;
    .counter-suffix {
      opacity: .65;
    }
  }
}