@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.step-details {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem 0;
  .inputs {
    //display: flex;
    justify-content: space-evenly;
    //flex-wrap: wrap;
    gap: 1rem;
    .input-container {
      width: 35rem;
      max-width: calc(100vw - 2rem);
      margin-bottom: 1rem;
      label {
        display: block;
        font-size: .8em;
        opacity: .65;
        margin-bottom: .5rem;
      }
      .content {
        position: relative;
        display: flex;
        &.company-toggler {
          cursor: pointer;
          .mdi-icon {
            margin-top: -.2em;
            margin-right: .25em;
          }
        }
        input {
          width: 100%;
          &.is-button-connected {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
        button.button {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          &:hover {
            scale: 1;
          }
        }
        .denominator {
          position: absolute;
          top: .6em;
          right: .6em;
          padding: .5em;
          backdrop-filter: blur(5px);
          background-color: rgba(#444, .25);
          border-radius: .5em;
          color: #aaa;
          &.clickable {
            cursor: pointer;
            transition: .25s ease-out;
            &:hover {
              color: black;
              background-color: rgba($goldenYellow, 75%);
            }
          }
        }
      }
    }
  }
  .info {
  }
}