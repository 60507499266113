@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

$hideNavCutoff: 1450px;

nav {

  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, .25);
  padding: .65rem .25rem .5rem .25rem;
  border-radius: .75rem;
  backdrop-filter: blur(5px);
  transition: .25s ease-out;
  @include addShadow();

  .separator {
    height: 3px;
    width: 80%;
    margin: 0 0 1rem 10%;
    background-color: black;
    border-bottom: 1px solid lighten($documentBackground, 10%);
  }

  a {
    border: 0;
    padding: .5rem 1rem;
    transition: .25s ease-out;
    border-radius: .5rem;
    background-color: transparent;
    color: $goldenYellow;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    font-size: 3em;

    .mdi-icon {
      @include md-icon-size(1em);
      fill: url(#golden-gradient) $goldenYellow;
    }

    &:hover {
      color: white;
      scale: 1.2;

      .mdi-icon {
        fill: $goldenOrange;
      }
    }

    &.is-selected {
      .mdi-icon {
        fill: white;
        filter: drop-shadow(0 0 3px $goldenOrange);
      }
    }
  }

  &.sub-nav {
    display: none;
    right: 8rem;
    bottom: 2.5rem;
    padding: .25rem .25rem .25rem .25rem;

    a {
      &.is-active {
        .mdi-icon {
        fill: $goldenRed;
      }
      }
      .mdi-icon {
        @include md-icon-size(.85em);
      }
    }
  }

  @media screen and (min-width: $hideNavCutoff) {
    &.toggler {
      display: none;
    }
    .insta-close-button {
      display: none;
    }
  }

  @media screen and (max-width: $hideNavCutoff) {
    right: .5rem;
    bottom: 3rem;
    a {
      font-size: 2em;

      .mdi-icon {
        @include md-icon-size(1em);
      }
    }
    &.is-hidden {
      right: -4.5rem;
    }
    &.sub-nav {
      right: 6rem;
      bottom: 5rem;

      a {
        .mdi-icon {
          @include md-icon-size(.9em);
        }
      }

      &.is-hidden {
        right: -4.5rem;
      }
    }
    &.toggler {
      text-align: center;
      width: calc(100vw - 4rem);
      bottom: 2rem;
      padding: 0;
      left: 2rem;

      a {
        padding-top: .25em;
        margin-left: 1.75rem;

        .mdi-icon {
          margin-bottom: -.4rem;
          margin-left: -1.2rem;
          animation: rotationAnimation2 linear 1.5s;
          animation-iteration-count: infinite;
          scale: 1.3;
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              $idx: $i - 1;
              animation-delay: #{.15s + ($idx * .15s)};
            }
          }
        }
      }

      &.is-hidden {
        left: -100vw;
      }
    }
  }
}

@keyframes rotationAnimation2 {
  0% {
    opacity: 0;
    transform: translateX(0rem);
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-.75rem);
  }
}