@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-list {
  position: relative;
  margin: .5rem auto;
  max-width: 1160px;
  text-align: center;

  .options-bar {
    margin: 1rem;
    text-align: left;
  }

  .links {
    margin: 1rem;
    gap: 1rem;
    justify-content: center;
  }

  .palette-test {
    height: 100px;
    display: flex;
    display: none;

    div {
      flex: 1 1 10px;

      &.p3 {
        background-color: $positiveL3;
      }

      &.p2 {
        background-color: $positiveL2;
      }

      &.p1 {
        background-color: $positive;
      }

      &.n1 {
        background-color: $negative;
      }

      &.n2 {
        background-color: $negativeL2;
      }

      &.n3 {
        background-color: $negativeL3;
      }
    }
  }

  .asset-list-composition-bar {
    margin: 1rem 2.25rem 1rem 2rem;
    height: 3.8rem;
    padding: .1rem;
    background-color: darken($documentBackground, 4%);
    @include addShadow();
    @media screen and (max-width: 800px) {
      margin: .35rem;
    }
  }

  .date-filter {
    position: relative;
    cursor: pointer;
    margin: 1rem 2rem;
    padding: .75rem;
    transition: .25s ease-out;
    @media screen and (max-width: 800px) {
      margin: .5rem;
      padding: .5rem;
    }

    .flex-vertical-center {
      justify-content: center;

      .desc {
        color: mix(white, $documentBackground, 50%);
        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      .days-since {
        color: lighten($negative, 12%);
        background-color: rgba(white, 7.5%);
        padding: 1px 4px;
        border-radius: 5px;
      }

      .direction {
        margin-left: .1rem;
        margin-right: -.35rem;
        margin-top: -.4rem;

        .direction-icon {
          &.is-negative {
            margin-top: .4rem;
          }

          @include md-icon-size(1em);
        }
      }

      .close-icon {
        opacity: 25%;
        transition: .25s ease-out;
        position: absolute;
        right: .5rem;
        top: .52rem;
        .mdi-icon {
          @include md-icon-size(1.8rem);
          fill: $goldenRed;
          filter: drop-shadow(0 0 2px lighten($goldenRed, 5%));
        }
        @media screen and (max-width: 800px) {
          top: .44rem;
          right: .44rem;
          opacity: 65%;
          .mdi-icon {
            @include md-icon-size(1.5rem);
          }
        }
      }
    }
    &:hover {
      background-color: rgba(mix(black, $red, 85%),25%);
      .close-icon {
        opacity: 1;
      }
    }
  }

  table {
    tbody {
      tr {
        cursor: pointer;

        .counter {
          transition: .25s ease-out;
        }

        &:hover {
          td {
            @media screen and (min-width: 801px) {
              color: $goldenYellow;
            }
          }
        }
      }
    }

    tr {
      td {
        transition: color .25s ease-out;



        .rate {
          position: relative;
          display: inline-block;
          gap: .5em;

          .asset-ratio {
            margin-left: 2rem;
          }

          .ath-badge {
            position: absolute;
            top: -.5em;
            right: -1.25em;
            scale: 65%;
            rotate: 25deg;
            @media screen and (max-width: 800px) {
              scale: 55%;
              top: -.6em;
              right: -1.75em;
            }
          }

          .counter {
            &.is-golden {
              color: white;
              text-shadow: 0 0 5px $yellow;
            }
          }
        }

        &.old-rate {
          color: #888;

          .direction-icon {
            opacity: .5;
          }

          .small {
            opacity: .5;
          }
        }

        .asset-icon {
          scale: 125%;
          margin-right: .25rem;
        }

        &.asset-name {
          position: relative;
          max-width: 50vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 800px) {
            .name {
              position: relative;
              max-width: 50vw;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .change-flavor-icon {
            @include md-icon-size(1.2rem);
            margin-top: -.15rem;
            margin-left: .1em;
          }

          .code {
            position: relative;
            opacity: 40%;
            font-size: .8em;
          }

        }

        .counter-suffix, .counter-prefix {
          opacity: 50%;
          font-size: .9em;
        }

        .rate-freshness {
          font-size: .75em;
          color: lighten($negative, 12%);
          //border: 1px solid rgba(white, 10%);
          background-color: rgba(white, 7.5%);
          padding: 0 3px 0 3px;
          border-radius: 5px;
        }

        &.recent-history {
          //padding-left: .25rem;
          img {
            height: 2rem;

            &.is-old {
              opacity: 27%;
            }

            &.is-positive-l3 {
              filter: drop-shadow(0px 0px 2px $red) brightness(150);
              &.is-old {
                filter: brightness(150);
              }
            }
            &.is-ath {
              filter: drop-shadow(0px 0px 3px $orange) brightness(200);
              &.is-old {
                filter: brightness(150);
              }
            }
          }

          @media screen and (max-width: 800px) {
            padding: 0;
            img {
              height: 1.35rem;
            }
          }
        }

        &.value {
          .comp-rate {
            .counter-prefix {
              font-size: .75em;
              margin-right: .55em;
              vertical-align: .075em;
            }
          }
        }

        &.rate {
          position: relative;
          color: #bbb;
          white-space: nowrap;
          //padding-right: 0;



          .flex-vertical-center {
            justify-content: flex-end;
            gap: .5em;
          }


        }

        &.change {
          @media screen and (max-width: 800px) {
            padding-left: .1rem;
          }
        }

        .direction-icon {
          @include md-icon-size(.95em);
          margin-right: .2em;
          vertical-align: -.05em;

          &.is-negative {
            vertical-align: -.15em;
          }
        }
      }
    }
  }
}