@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.composition-bar {
  position: relative;
  background-color: darken($documentBackground, 5%);
  height: 7rem;
  border-radius: .5rem;
  margin-top: 1.25rem;
  margin-bottom: 4rem;
  padding: .2rem;
  @media screen and (max-width: 500px) {
    margin: .5rem .5rem 4rem .5rem;
  }

  .selector {
    margin: .25rem 0 0 1.75rem;
    @media screen and (max-width: 500px) {
      margin: .15rem 0 0 .25rem;
    }
    button {
      border: 0;
      padding: .5rem .5rem;
      transition: .25s ease-out;
      border-radius: .5rem;
      background-color: transparent;
      color: mix(white, $documentBackground, 35%);
      cursor: pointer;
      &.is-selected {
        color: white;
      }
      &:hover {
        color: white;
      }
    }
    .playback {
      .mdi-icon {
        @include md-icon-size(1.15em);
        vertical-align: -.15em;
      }
    }
  }

  .information-card {
    position: absolute;
    left: calc(var(--percentOffset) - 10rem);
    bottom: -1.75rem;
    pointer-events: none;
    transition: .15s ease-out;

    &.is-hidden {
      transform: translateY(-.5rem);
      opacity: 0;
    }

    .content {
      background-color: darken($documentBackground, 7%);
      padding: .5rem 1rem;
      width: 20rem;
      display: inline-block;
      border-radius: .65rem;
      @include addShadow();

      .numbers {
        display: flex;
        gap: .5rem;
        justify-content: center;
        align-items: flex-end;
        font-size: .7rem;
        opacity: 50%;

        .suffix {
          opacity: 65%;
          font-size: .9em;
        }

        .percentage {
          color: $goldenYellow;
          font-size: 1.2em;
        }
      }

      .item-label {
        margin-top: .25rem;
        text-align: center;
        max-width: 18rem;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .composition-bar-items {
    display: flex;
    gap: 0;
    height: 100%;
    border-radius: .35rem;
    overflow: hidden;

    .item {
      position: relative;
      flex-basis: var(--percent);
      transition: .25s ease-out;
      cursor: pointer;
      overflow: hidden;

      //@include addInnerShadow(rgba(black,.35), 20px);
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(black, 10%), rgba(black, 30%));
      }

      &:nth-child(odd) {
        background: $documentBackground;
      }

      &:nth-child(even) {
        background: darken($documentBackground, 2%);
      }

      &:hover {
        background-color: lighten($documentBackground, 4%);
        .item-label {
          .name, .code {
            color: $goldenYellow;
            text-shadow: 0 0 8px $goldenYellow;
          }
        }
      }

      display: flex;
      align-items: center;
      justify-content: center;

      &-label {
        transition: flex-basis .25s ease-out;
        text-align: center;
        white-space: nowrap;
        .name {
          max-width: 18rem;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: color .25s ease-out;
          @media screen and (max-width: 800px) {
            max-width: 7rem;
          }
        }
        .code {
          transition: color .25s ease-out;
        }
        .counter {
          .counter-suffix, .magnitude {
            font-size: .8em;
            opacity: 80%;
          }
        }
        .values {
          justify-content: center;
          font-size: .8em;
          margin-top: .15em;
          gap: .5rem;
          color: mix(white, $documentBackground, 60%);
          .absolute {
            color: mix(white, $documentBackground, 40%);
          }
        }
      }

      &.is-positive {
        background: mix($documentBackground, $positive, 85%);
        .item-label {
          color: mix(white, $positive, 80%);
          .absolute {
            color: mix(white, $positive, 40%);
          }
        }
        &:nth-child(even) {
          background: mix($documentBackground, $positive, 92%);
        }
        &:hover {
          background: mix($documentBackground, $positive, 77%);
          .name, .code {
            color: white;
            text-shadow: 0 0 8px $positive;
          }
        }
      }
      &.is-negative {
        background: mix($documentBackground, $negative, 85%);
        .item-label {
          color: mix(white, $negative, 80%);
          .absolute {
            color: mix(white, $negative, 40%);
          }
        }
        &:nth-child(even) {
          background: mix($documentBackground, $negative, 92%);
        }
        &:hover {
          background: mix($documentBackground, $negative, 77%);
          .name, .code {
            color: white;
            text-shadow: 0 0 8px $negative;
          }
        }
      }

      @media screen and (min-width: 801px) {
        .item-label {
          .name, .values {
            display: none;
          }
        }
        &.pct-0 {
          .item-label {
            display: none;
          }
          &:hover {
            &:not(.is-colored){
              background-color: $goldenYellow;
            }
          }
        }
        &.pct-1 {
          .item-label {
            transform: rotate(90deg);
            max-width: 1rem;
            overflow: hidden;
            font-size: .15em;
          }
        }
        &.pct-2 {
          .item-label {
            transform: rotate(90deg);
            max-width: 2rem;
            overflow: hidden;
            font-size: .3em;
          }
        }
        &.pct-3,
        &.pct-4,
        &.pct-5,
        &.pct-6,
        &.pct-7,
        {
          .item-label {
            transform: rotate(90deg);
            max-width: 2.5rem;
            overflow: hidden;
          }
        }
        &.pct-30,
        {
          .item-label {
            .code {
              display: none;
            }
            .name {
              display: block;
            }
            .values {
              display: flex;
            }
          }
        }
        &.pct-10,
        &.pct-15,
        &.pct-20,
        {
          .item-label {
            .values {
              display: flex;
            }
          }
        }
      }
      @media screen and (max-width: 800px) {
        .item-label {
          .name, .values {
            display: none;
          }
        }
        &.pct-30,
        {
          .item-label {
            .code {
              display: none;
            }
            .name {
              display: block;
            }
            .values {
              display: flex;
            }
          }
        }
        &.pct-20,
        &.pct-15,
        {
          .item-label {
            .values {
              display: flex;
              .absolute {
                display: none;
              }
            }
          }
        }
        &.pct-8,
        &.pct-9,
        &.pct-10,
        {
          .item-label {
            transform: rotate(90deg);
            max-width: 2.5rem;
            overflow: hidden;
          }
        }
        &.pct-0,
        &.pct-1,
        &.pct-2,
        {
          .item-label {
            display: none;
          }
          &:hover {
            background-color: $goldenYellow;
            &.is-colored {
              background-color: white;
            }
          }
        }
        &.pct-7,
        &.pct-6,
        {
          .item-label {
            transform: rotate(90deg);
            max-width: 2rem;
            overflow: hidden;
            font-size: .3em;
          }
        }
        &.pct-5,
        &.pct-4,
        {
          .item-label {
            transform: rotate(90deg);
            max-width: 2rem;
            overflow: hidden;
            font-size: .15em;
          }
        }
        &.pct-3,
        {
          .item-label {
            transform: rotate(90deg);
            max-width: 2rem;
            overflow: hidden;
            font-size: .1em;
          }
        }
      }
    }
  }
}