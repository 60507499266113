@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-transactions {
  margin: .5rem auto;
  max-width: 1160px;

  .options-bar {
    justify-content: space-between;
    margin: 1em 1.2em 1.5em 1.2em;
    @media screen and (max-width: 800px) {
      margin: .65em .5em .25em .5em;
    }
  }

  table {
    width: 100%;

    td {
      cursor: pointer;

      &.date {
      }

      &.type {
        width: 2rem;
      }

      .magnitude {
        opacity: .65;
      }

      .comment {
        opacity: 40%;
        max-width: 25rem;
      }

      .additive-icon {
        scale: 60%;
        margin-right: -.15em;
        vertical-align: -.4em;
      }

      .direction-icon {
        @include md-icon-size(.95em);
        margin-right: .2em;
        vertical-align: -.05em;
        &.is-negative {
          vertical-align: -.15em;
        }
      }

      &.is-gas-cost {
        color: #aaa;
      }

      &.asset-name {
        max-width: 40vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .flex-box {
          gap: .35rem;
        }
        .swap-from {
          opacity: 70%;
          font-size: .8em;
        }
        .swap-icon {
          margin: 0;
          @include md-icon-size(.85em);
        }
        .mobile-date {
          gap: .25rem;
        }
      }

      .counter-suffix, .counter-prefix {
        opacity: 50%;
        font-size: .9em;
      }

      &.hide-desktop {
        .mobile-amount {
          justify-content: flex-end;
          gap: .35rem;
          .verb {
            font-size: .7em;
            text-transform: lowercase;
            opacity: 80%;
          }
        }
      }

      &.is-dividend {
        color: $goldenYellow;

        .direction-icon, .additive-icon {
          fill: $goldenYellow;
        }
      }

      .qty-full, .cost-full {
        .counter-suffix {
          font-size: .8em;
          vertical-align: .1em;
          margin-left: .15em;
        }
      }
    }
  }
}