@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-kpis {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0 0 0;
  @media screen and (max-width: 800px) {
    gap: .5rem;
    padding: .5rem;
    margin: 0;
  }

  .kpi {
    flex: 1 1 15rem;
    order: 10;

    @media screen and (max-width: 800px) {
      flex-basis: 12rem;
      font-size: 0.75em;
    }

    h3 {
      white-space: nowrap;
      em {
        font-style: normal;
        color: mix(white, $documentBackground, 85%);
      }
    }

    &-value {
      margin-top: .25em;
      font-size: 1.5em;
      display: flex;
      align-items: center;
      gap: .35em;
      white-space: nowrap;

      .direction-icon {
        vertical-align: -.175em;
        margin-right: -.25em;
      }

      .counter-suffix, .counter-prefix, .magnitude {
        opacity: 50%;
        font-size: .9em;
      }

      .secondary {
        font-size: .8em;
      }

      .recalc {
        margin-top: -.25rem;
        margin-bottom: -.5rem;
        display: flex;
        flex-direction: column;
        gap: .55rem;
        .recalc-line {
          font-size: .72em;
          .mdi-icon {
            @include md-icon-size(1.05em);
          }
        }
        @media screen and (max-width: 800px) {
          margin-top: -.25rem;
          margin-bottom: 0;
        }
      }
    }

    .weak {
      opacity: .5;
    }

    .mdi-icon {
      opacity: .75;

      &.is-golden {
        fill: url(#golden-gradient) $goldenYellow;
        scale: 1.2;
      }
    }

    &.large-content {
      .mdi-icon {
        @include md-icon-size(1.25em);
        margin-left: .5em;
      }
    }

    &.golden {
      .kpi-value {
        color: $goldenYellow;
      }
    }

    @media screen and (max-width: 800px) {
      &.total-value {
        order: 0;
      }
      &.return {
        order: 1;
      }
      &.returns-annualized {
        order: 2
      }
    }
  }
}