@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.table {

  border-spacing: 0;
  border-collapse: separate;
  width: 100%;

  thead {
    th {
      text-align: left;
      padding: .5rem .75rem;
      font-size: .8em;
      font-weight: normal;
      color: #888;
    }
  }

  tbody {
    tr {
      .counter {
        transition: .25s ease-out;
      }

      &.tr-clickable {
        cursor: pointer;
        color: $goldenOrange;
        &:hover, &:active {
          color: $goldenYellow;
        }
      }

      &:nth-child(even) {
        td, th {
          background-color: rgba(0, 0, 0, .25);


        }
      }

      td {
        transition: .25s ease-out;

        &.fade-left, &.fade-right {
          position: relative;

          &:after {
            top: 0;
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        &.fade-left {
          border-radius: .5rem 0 0 .5rem;
          &:after {
            left: 0;
            background: linear-gradient(to right, rgba($documentBackground, 1), rgba($documentBackground, 0));
          }
        }

        &.fade-right {
          border-radius: 0 .5rem .5rem 0;
          &:after {
            right: 0;
            background: linear-gradient(to left, rgba($documentBackground, 1), rgba($documentBackground, 0));
          }
        }
      }

      &:hover {
        td {
          @media screen and (min-width: 801px) {
            //color: $goldenYellow;
            background-color: rgba(0, 0, 0, .55);
          }
        }
      }

    }
  }

  td, th {
    text-align: left;
    padding: .5rem .75rem;

    &.fade-left {
    }

    &.no-padding-left {
      padding-left: 0;
    }

    .direction-icon {
      @include md-icon-size(1.075em);
      vertical-align: -.2em;
      margin-right: .1em;
    }
  }
  &.rounded, &.is-rounded {
    tbody {
      td {
        $radius: .35rem;
        &:first-child {
          border-radius: $radius 0 0 $radius;
          &:last-child {
            border-radius: $radius;
          }
        }
        &:last-child {
          border-radius: 0 $radius $radius 0;
        }
      }
    }
  }

  &.is-in-card {
    tr {
      td:first-child, th:first-child {
        padding-left: 1rem;
      }
      td:last-child, th:last-child {
        padding-right: 1rem;
      }
      &:first-child td {
        //padding-top: 1rem;
      }
      &:last-child td {
        padding-bottom: 1rem;
      }
    }
  }
}