@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

$lineHeight: 5px;
$lineColor: rgba(black, 0.3);

.ui-wizard {

  &__progress {
    display: flex;
    margin-bottom: 2rem;
    list-style: none;
    padding: 0;

    .step {
      flex: 3;
      text-align: center;

      &-number {
        transition: 0.15s ease-out;
        font-size: 1rem;
        margin: 0.5rem;
        color: #444;
        @include addInnerShadow(rgba(black,.35), 20px);
      }
      &-name {
        margin-top: 0.5rem;
        color: #666;
        @media screen and (max-width: 800px) {
          display: none;
        }
      }

      &.inactive {
        .step-name {
        }
        .step-number {
        }
      }

      &.started {
        .step-number {
          background-color: #1a1a1a;
          color: $goldenYellow;
          font-weight: bold;
          scale: 1.06;
        }
      }

      &.finished {
        .step-number {
          color: #fff;
          font-weight: bold;
        }
      }

      &.active {
        .step-number {
          background: linear-gradient(to bottom, $yellow, $goldenRed);
          color: #222;
          scale: 1.21;
          font-weight: bold;
        }
        .step-content {
          .left-connector {
            background: linear-gradient(to left, darken($goldenYellow, 10%), $lineColor 5rem);
            @media screen and (max-width: 1150px) {
              background: linear-gradient(to left, darken($goldenYellow, 10%), $lineColor 2rem);
            }
          }
          .right-connector {
            background: linear-gradient(to right, darken($goldenYellow, 10%), $lineColor 5rem) ;
            @media screen and (max-width: 1150px) {
              background: linear-gradient(to right, darken($goldenYellow, 10%), $lineColor 2rem);
            }
          }
        }
      }

      &:first-child {
        flex-grow: 2;

        &-content {
          align-items: flex-start;
        }

        .step-name {
          text-align: left;
        }

        .left-connector {
          display: none;
          background-color: transparent;
        }
      }

      &:last-child {
        flex-grow: 2;

        &-content {
          align-items: flex-end;
        }

        .step-name {
          text-align: right;
        }

        .right-connector {
          display: none;
          background-color: transparent;
        }
      }

      &-content {
        list-style: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0;
        align-items: center;
        flex-grow: 2;

        .left-connector {
          flex: 1;
          height: $lineHeight;
          border-radius: 0 $lineHeight $lineHeight 0;
          background-color: $lineColor;
        }

        .step-number {
          /* Center the content */
          align-items: center;
          display: flex;
          justify-content: center;
          border: 0;
          &:not(:disabled) {
            cursor: pointer;
          }

          /* Rounded border */
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 9999px;
          height: 32px;
          width: 32px;

          /* OPTIONAL: Spacing between it and connectors */
          margin-left: 0.5rem;
          margin-right: 0.5rem;

          svg {
            @include md-icon-size(1.25rem);
            margin: 0;
          }
        }

        .right-connector {
          flex: 1;
          height: $lineHeight;
          border-radius: $lineHeight 0 0 $lineHeight;
          background-color: $lineColor;
        }
      }
    }
  }
}
