@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.change-flavor-icon {
  &.p10 {
    animation-name: blinking-gold;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  &.p03 {
    color: $goldenYellow;
  }
  &.n03 {
    color: $negativeL2;
  }
  &.n10 {
    color: $negativeL3;
  }
}