@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.stat-line {
  margin: 1rem 0 1.25rem 0;
  gap: 1rem;
  padding: 0 .15rem;
  flex-wrap: wrap;
  @media screen and (max-width: 800px) {
    padding: 0 .5rem;
    gap: 1rem;
    margin-bottom: .5rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 0.75rem;
  }

  .stat-point {
    border: 1px solid #3a3a3a;
    padding: .25em 1.25em .25em .8em;
    border-radius: 2rem;
    gap: .5rem;
    background-color: darken($documentBackground, 1%);
    @include addShadow();
    @media screen and (max-width: 500px) {
      //flex-grow: 1;
    }

    .icon {
      padding-top: .225rem;

      .mdi-icon {
        fill: url(#golden-gradient) $goldenYellow;
        @include md-icon-size(1.22em);
      }
    }

    .value {
      font-size: 1.5em;
    }

    .secondary-value {
      opacity: 50%;
      font-size: 1.25em;
    }

    .counter-suffix {
      font-size: .8em;
      opacity: 65%;
      margin-left: .15em;
    }

    &.reduced-gap {
      gap: .2rem;
    }
  }
}