@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.chart-focus {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  .buttons {
    background-color: transparent;
    padding: .25rem;
    border-radius: .75rem;
    button {
      border: 0;
      padding: .5rem .85rem;
      transition: .25s ease-out;
      border-radius: .5rem;
      background-color: transparent;
      color: $goldenYellow;
      font-weight: bold;
      cursor: pointer;
      &.is-selected {
        color: white;
        text-shadow: 0 0 8px $goldenYellow;
      }
      &:hover {
        color: white;
      }

      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        padding-right: 1rem;
      }

    }
  }
}