.icon-gradients {
  opacity: 0;
  height: 0;
  width: 0;
  position: fixed;
  left: -1rem;
  top: -1rem;
}

#golden-gradient {
  --color-stop: #f1c40f;
  --color-bot: #e74c3c;
}