@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.dashboard-sub-nav {
  font-size: .8rem;
  .links {
    padding: .5rem 1.5rem .7rem 1.25rem;
    border-radius: 0 0 1rem 1rem;
    background: linear-gradient(to bottom, rgba(black,20%), rgba(black,10%));
    display: inline-flex;
    gap: 1rem;
    @media screen and (max-width: 800px) {
      padding: .75rem 1.5rem .7rem 1.05rem;
      border-radius: 0 0 .5rem 0;
    }
    align-items: center;

    a {
      display: flex;
      transition: .25s ease-out;
      border-radius: .5rem;
      background-color: transparent;
      color: $goldenYellow;
      text-decoration: none;
      font-weight: normal;
      align-items: center;
      gap: .5rem;

      &:first-of-type {
        @media screen and (max-width: 800px) {
          //margin-right: auto;
        }
      }

      .mdi-icon {
        @include md-icon-size(1.2em);
      }

      &:hover, &:active {
        color: $goldenOrange;
      }

      &.is-active {
        color: white;
        text-shadow: 0 0 4px $goldenOrange;
      }

      @media screen and (max-width: 400px) {
        //&:not(.is-active) .text {
        .text {
          display: none;
        }
      }
    }
  }
}