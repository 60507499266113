@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.live-changes {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  bottom: 0;
  left: 0;
  padding: .5rem .5rem;
  &:after {
    position: absolute;
    content: '';
    width: 125px;
    height: 185px;
    left: 0;
    bottom: 0;
    background: radial-gradient(at bottom left, rgba(darken($documentBackground, 35%), 25%), transparent 70%);
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
  .change {
    white-space: nowrap;
    gap: .25rem;
    opacity: 0;
    animation: SlideInOut 270s ease-out;
    .additive-icon {
      @include md-icon-size(0.5rem);
      opacity: 45%;
    }
    .value {
      opacity: 70%;
      font-size: 1em;
      .magnitude, .counter-suffix {
        opacity: 80%;
        font-size: .8em;
      }
    }
    .desc {
      color: white;
      font-size: 0.6em;
      padding-left: .15em;
      .code {
        color: mix(white, $documentBackground, 75%);
      }
    }
    .time-ago {
      font-size: 0.75em;
      color: mix(white, $documentBackground, 40%);
      .suffix {
        opacity: 70%;
        font-size: .8em;
        margin-left: .1em;
      }
    }
  }
}

@keyframes SlideInOut {
  0% {
    font-size: 1.2rem;
    opacity: 0;
    transform: translateY(1rem);
  }
  .5% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    font-size: 1rem;
  }
  95% {
    opacity: 1;
    transform: translateY(0);
  }
  99% {
    transform: translateY(0);
  }
  100% {
    font-size: .65rem;
    opacity: 0;
    transform: translateY(-1rem);
  }
}

@keyframes ChangeSlideUp {
  0% {
    opacity: 0;
    transform: translateY(0);
    color: $positive;
  }
  10% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-3rem);
    color: mix($positive, $yellow, 50%);
  }
}
@keyframes ChangeSlideDown {
  0% {
    opacity: 0;
    transform: translateY(0);
    color: $negative;
  }
  10% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(3rem);
    color: mix($red, $negative, 25%);
  }
}