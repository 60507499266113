@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-settings {
  margin: 1rem auto;
  max-width: 1120px;
  @media screen and (max-width: 500px){
    margin: 1rem;
  }
  section {
    position: relative;
    h2 {
      font-weight: normal;
    }
    .split-icon {
      rotate: 90deg;
    }
    .illustration-icon {
      position: absolute;
      right: 0;
      top: 1.25rem;
      @include md-icon-size(5.25rem);
      fill: darken($documentBackground, 2%)
    }
    &:after {
      content: '';
      display: block;
      width: calc(100% - 10rem);
      height: 2px;
      background-color: rgba(darken($documentBackground, 10%), 50%);
      border-bottom: 1px solid rgba(lighten($documentBackground, 25%), 50%);
      margin: 2rem 5rem;
      @media screen and (max-width: 500px){
        width: calc(100% - 4rem);
        margin: 2rem;
      }
    }
  }
}