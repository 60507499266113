.currency-picker {
  .currencies {
    height: 4rem;
    .currency {
      flex: 1 1 10rem;
      .symbol {
        opacity: 50%;
      }
    }
  }
}