@import "src/modules/util/scss/colors";

.loader-circle {

  display: inline-block;
  animation: rotationAnimation linear .7s;
  animation-iteration-count: infinite;
  height: 3rem;
  width: 3rem;
  border-top: 0.25rem solid darken($documentBackground, 3);
  border-left: 0.25rem solid darken($documentBackground, 3);
  border-bottom: 0.25rem solid $negative;
  border-right: 0.25rem solid darken($negative, 3%);
  border-radius: 50%;
  vertical-align: middle;
  &.is-positive {
    border-bottom: 0.25rem solid $positive;
    border-right: 0.25rem solid darken($positive, 10%);
  }
  &.is-golden {
    border-bottom: 0.25rem solid $goldenYellow;
    border-right: 0.25rem solid mix($goldenYellow,$goldenOrange,50%);
  }
}

@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}