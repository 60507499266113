@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .75rem 2rem;
  border-radius: .5rem;
  border: 0;
  font-weight: bold;
  color: #ddd;
  cursor: pointer;
  transition: .25s ease-out;
  overflow: hidden;
  &.has-icon {
    padding-left: 1.1rem;
    gap: .5rem;
    &.is-icon-right {
      padding-left: 2rem;
      padding-right: 1.2rem;
    }
  }
  .icon {
    .mdi-icon {
      vertical-align: -.235rem;
      fill: url(#golden-gradient) $goldenYellow;
    }
  }
  //background: linear-gradient(to top, rgba(black, 25%), rgba(black, 10%));

  position: relative;
  background-color: darken($documentBackground, 4%);
  .button-content {
    position: relative;
  }

  &:hover:not(:disabled) {
    color: $goldenYellow;
    background-color: darken($documentBackground, 6%);
    &:before {
      //top: -100%;
    }
  }
  &.is-red {
    &:before {
      background: linear-gradient(to top, rgba(red, 10%), rgba(red, 35%));
    }
    background: linear-gradient(to top, rgba(red, .1), rgba(red, .25));
    &:hover:not(:disabled) {
      background: linear-gradient(to top, rgba(red, .2), rgba(red, .35));
    }
    .icon {
      .mdi-icon {
        fill: white;
      }
    }
  }
  &.is-checked {
    background: linear-gradient(to bottom, rgba($yellow, 75%), rgba($goldenRed, 75%));
    color: $documentBackground;
    .icon {
      .mdi-icon {
        fill: $documentBackground;
      }
    }
    &:hover:not(:disabled) {
      background: linear-gradient(to bottom, rgba($yellow, 75%), rgba($goldenRed, 75%));
      color: $documentBackground;
    }
  }
  &.is-icon-only {
    padding: 0;
    margin: 0;
    background-color: transparent;
    gap: 0;
    &:hover:not(:disabled) {
      background-color: transparent;
    }
  }
  &:disabled {
    cursor: not-allowed;
    color: #666;
    background: linear-gradient(to top, rgba(#444, .25), rgba(#444, .51));
    .mdi-icon {
      fill: #666;
    }
  }
}