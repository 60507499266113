@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

$base-color: $documentBackground;
$card-background-color: #888;

#popup {

  position: fixed;
  left: 0;
  right: 0;
  width: calc(100vw - 2rem);
  max-width: 38.5rem;
  top: 0;
  margin: 1rem auto; /* Will not center vertically and won't work in IE6/7. */
  //color: $base-color;
  text-align: left;
  z-index: 100;
  @media screen and (min-height: 1000px) {
    top: 10rem;
  }
  &.is-wide {
    max-width: 58.5rem;
    top: 0;
  }

  &.active {
    opacity: 1;
    transition: opacity .25s ease-out, top .25s ease-out;
  }

  &.inactive {
    opacity: 0;
    transition: opacity .25s ease-in, top .25s ease-in;
    pointer-events: none;
    top: -1rem;
    @media screen and (min-height: 1000px) {
      top: 8rem;
    }
  }

  &.no-content {
    background-color: #9f59ef !important;
    left: -100%;
    margin: 0;
  }

  &__modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparentize(#000, .6);
  }

  &__container {
    position: relative;
    background: linear-gradient(165deg, darken(rgba($documentBackground, .5), 10%), rgba($documentBackground, .5));
    backdrop-filter: blur(13px);
    padding: .75rem 0 .85rem 0;
    @include addShadow();
    @media screen and (min-width: 650px) {
      padding: 1.5rem 0;
    }
    border-radius: .75rem;

    &__content {
      padding: 0 1rem;
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
      overflow-x: hidden;
      @media screen and (min-width: 650px) {
        padding: 0 2rem;
      }
    }

    &__close {
      position: absolute;
      top: .25rem;
      right: .25rem;
      @media screen and (min-width: 650px) {
        top: .5rem;
        right: .5rem;
      }
      height: 2.3rem;
      width: 2.3rem;
      padding: .3rem 0 0 0;
      border: 0;
      border-radius: 50%;
      text-align: center;
      background-color: transparent;

      .mdi-icon {
        fill: white;
      }

      &:hover, &:focus {
        cursor: pointer;
        outline: none;
        transition: .25s ease-out;

        .mdi-icon {
          fill: $goldenYellow;
        }
      }
    }

    h1, h2, h3 {
      margin-top: 0;
    }

  }

  a {
    color: lighten($card-background-color, 40);
    text-decoration: none;
    transition: .15s ease-out;

    .mdi-icon {
      @include md-icon-size(1.4rem);
      transform: translateX(-.3rem);
      transition: inherit;
      vertical-align: -.35rem;
      fill: lighten($card-background-color, 40);
    }

    &:hover, &:focus {
      color: lighten($card-background-color, 15) !important;

      .mdi-icon {
        fill: lighten($card-background-color, 15);
      }
    }
  }

  .calculation {
    a {
      color: lighten($card-background-color, 30);

      .mdi-icon {
        @include md-icon-size(1.2rem);
        vertical-align: -.35rem;
      }

      &:hover, &:focus {
        color: darken($orange, 10);
      }
    }
  }

}