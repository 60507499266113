@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.add-rate-dialog {

  h2 {
    color: mix(white, $documentBackground, 50%);
    font-size: .9rem;
    font-weight: normal;
    .asset-name {
      display: block;
      color: white;
      font-size: 1.4rem;
    }
  }
  .button-container {
    text-align: right;
    margin: 1rem 0 .15rem 0;
  }
  @media screen and (max-width: 1150px) {
    input[type=number] {
      width: 12rem;
    }
  }
  .input-div {
    position: relative;
    margin-bottom: .5rem;
    input[type=number] {
      padding-left: 3.5rem;
    }
    .label {
      position: absolute;
      top: .5rem;
      left: .5rem;
      padding: .5rem .75rem;
      border-radius: .5rem;
      width: 2.5rem;
      text-align: center;
      background-color: rgba($documentBackground, 65%);
      color: $goldenYellow;
      font-weight: bold;
    }
  }
}