@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-compare {
  margin: 1rem auto;
  max-width: 1150px;

  .asset-selectors {
    .center-arrow {
      text-align: center;
      margin: 1rem;

      .std-icon {
        @include md-icon-size(1.35rem);
      }
    }

    &.is-selection-complete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .asset-picker {
        flex: 1 1 40%;
      }
    }
  }

  .result {
    .period {
      display: flex;
      justify-content: space-between;

      .asset, label {
        flex: 1 1 33.3%;
      }

      .asset {
        &.a2 {
          text-align: right;
        }
      }

      .label {
        min-width: 20rem;
        text-align: center;
      }
    }
  }
}