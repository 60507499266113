@import "../scss/colors";


.lazy-list-loader {
  text-align: center;
  margin: 1rem;

  button {
    font-size: .8rem;
    background-color: transparent;
    transition: .25s ease-out;
    color: white;
    opacity: .5;
    padding: 1rem 2rem;
    border-radius: .5rem;
    border: 0;
    &:hover, &:active {
      text-decoration: underline;
      color: mix($yellow, $orange, 25%);
    }
  }
}