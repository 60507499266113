@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.chart-hover {
  position: relative;
  min-width: 10rem;
  padding: .5rem .75rem .75rem 1.5rem;
  border-radius: 1rem 1rem 0 0;
  pointer-events: none;
  user-select: none;
  @media screen and (max-width: 800px) {
    padding-left: 1rem;
    border-radius: 0;
    padding-bottom: .5rem;
  }
  .value {
    font-size: 2.5em;
  }
  .date-diff-highlighter {
    .is-diff {
      color: $goldenYellow;
      text-shadow: 0 0 10px rgba($goldenYellow,1);
    }
  }
  .kpis {
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    .kpi {
      white-space: nowrap;
      h3 {
        font-size: .85rem;
        color: #888;
        margin: 0 0 .1em 0;
        font-weight: normal;
      }
      .counter-suffix, .counter-prefix, .magnitude{
        opacity: .65;
        font-size: .85em;
      }
      &-value {
        display: flex;
        align-items: center;
        font-size: 1.5em;
        .pct {
          font-size: .8em;
          vertical-align: middle;
          margin-left: .35em;
        }
      }
      &.focus-value {
        font-size: 1.65em;
        margin-bottom: -.1em;
        h3 {
          font-size: .9rem;
          margin-bottom: 0;
        }
        @media screen and (max-width: 800px) {
          font-size: 1.35em;
        }
      }
      &.change {
        h3 {
          padding-left: 1.85rem;
        }
      }

      .no-change {
        margin-top: .2em;
        font-size: .7em;
        opacity: .5;
      }

      .arrow {
        @include md-icon-size(1em);
        margin-right: .15em;
        margin-top: -.65em;
      }
    }
  }
}