@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.date-diff-highlighter {
  display: inline-block;
  color: #777;
  span {
    transition: .25s ease-out;
  }
  .is-match {
  }
  .is-diff {
    color: #bbb;
  }
}