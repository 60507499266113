@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.ath-badge {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  scale: 200%;
  .mdi-icon {
    position: absolute;
    margin: 0;
    fill: url(#golden-gradient) $goldenYellow;
    top: 0;
    left: 0;
  }
  .text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: bold;
    font-size: .45rem;
    color: darken($goldenOrange, 30%);
  }
}