@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes SlideDown {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes SlideUp {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes SlideRight {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes SlideLeft {
  0% {
    opacity: 0;
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes SlideDownNoOpacity {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes blinking-text {
  0% {
    color: orangered;
  }
  50% {
    color: $goldenYellow;
  }
  100% {
    color: orangered;
  }
}

@keyframes Rotate {
  0% {
    rotate: 0;
  }
  100% {
    rotate: -360deg;
  }
}

@keyframes blinking-gold {
  0% {
    color: $yellow;
  }
  50% {
    color: $goldenRed;
  }
  100% {
    color: $yellow;
  }
}
.children-slide-down > * {
  animation: SlideDown .6s ease-out;
  opacity: 0;
  transform: translateY(-1rem);
}
.children-slide-right > * {
  animation: SlideRight .6s ease-out;
  opacity: 0;
  transform: translateX(-1rem);
}

.children-slide-down, .children-slide-right {
  > * {
    animation-fill-mode: forwards;
  }
  > * {
    @for $i from 1 through 50 {
      &:nth-child(#{$i}){
        $idx: $i - 1;
        animation-delay: #{$idx * .05s};
      }
    }
  }
  &.s10 {
    > * {
      @for $i from 1 through 50 {
        &:nth-child(#{$i}){
          $idx: $i - 1;
          animation-delay: #{$idx * .10s};
        }
      }
    }
  }
  &.s15 {
    > * {
      @for $i from 1 through 50 {
        &:nth-child(#{$i}){
          $idx: $i - 1;
          animation-delay: #{$idx * .15s};
        }
      }
    }
  }
}