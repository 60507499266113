@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.settings {
  h2 {
    margin-bottom: .5rem;
  }
  h3 {
    margin: 0;
    opacity: .65;
    font-weight: normal;
  }
  .button {
    background-color: darken($documentBackground, 9%);
  }
  .toggle-container {
    margin: 1rem 0;
    .toggle {
      gap: .85rem;
    }
    &.dust {
      justify-content: space-between;
      .dust-thresholds {
        animation: SlideRight .6s ease-out;
        animation-fill-mode: backwards;
        gap: 0;
        .button {
          border-radius: 0;
          transition: .25s ease-out;
          padding: .5rem 1.25rem;
          &:first-child {
            border-radius: .5rem 0 0 .5rem;
          }
          &:last-child {
            border-radius: 0 .5rem .5rem 0;
          }
        }
      }
    }
  }
  .weak {
    opacity: .5;
    margin-left: .25rem;
  }
  .asset-picker {
    margin-bottom: 1rem;
  }
  .transaction-settings {
    margin-top: .5rem;
    display: flex;
    gap: 1rem;
    .mdi-icon {
      fill: url(#golden-gradient) $goldenYellow !important;
    }
    .bulk-link {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
  }
  .footer {
    justify-content: flex-end;
    margin: 1rem .2rem .2rem 0;
    .internal-link {
      .mdi-icon {
        margin-top: .1rem;
        fill: url(#golden-gradient) $goldenYellow !important;
      }
    }
    button.button {
      .mdi-icon {
        fill: $red;
      }
    }
  }
}