@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.toggle {
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  user-select: none;

  .label {
    opacity: .5;
    transition: .25s ease-out;
  }

  &:hover {
    .label {
      opacity: 1;
    }

    button {
      background-color: rgba(0, 0, 0, .5);

      &:after {
        background: rgba(#888, .5);
      }
    }
  }

  button {
    position: relative;
    height: 2rem;
    width: 3.5rem;
    border-radius: 3rem;
    background-color: rgba(0, 0, 0, .25);
    @include addInnerShadow(rgba(black, .35), 20px);
    border: 0;
    cursor: pointer;
    transition: .25s ease-out;
    margin: 0;

    &:after {
      content: '';
      position: absolute;
      height: 1.35rem;
      width: 1.35rem;
      top: .325rem;
      left: .4rem;
      background: rgba(#666, .5);
      border-radius: 50%;
      transition: left .075s ease-out, background-color .25s ease-out;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .5);

      &:after {
        background: rgba(#888, .5);
      }
    }
  }

  &.is-checked {
    .label {
      opacity: 1;
    }

    button {
      &:after {
        left: 1.75rem;
        background: $goldenYellow;
      }

      &:hover {
        &:after {
          background: $goldenRed;
        }
      }
    }
  }

  &.light-theme {
    button {
      background-color: #eee;
    }

    &.is-checked {
      button {
        background-color: white;
        @include addInnerShadow(rgba(black, .2), 20px);

        &:after {
          background: $goldenYellow;
          box-shadow: rgba(black, .75) 0 0 5px;
        }
      }
    }
  }
}