@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

th.sort-toggler {
  cursor: pointer;
  user-select: none;
  .flex-vertical-center {
    gap: 0;
    .icons {
      transform: translateY(.05em);
      margin-right: 0;
      margin-left: -.2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .mdi-icon {
        margin: -1.1em 0;
        transition: .25s ease-out;
        scale: 70%;
      }
    }
    &.flex-end {
      justify-content: flex-end;
      .icons {
        margin-right: -.2em;
      }
    }
  }
  &.is-active {
    &.is-asc {
      color: $negative;
      .icons .mdi-icon {
        transform: translateY(-.0.5em);
        rotate: 180deg;
      }
    }
    &.is-desc {
      color: $goldenYellow;
    }
  }
}