@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.filter-toggle-button {
  position: relative;
  padding: .75rem .75rem;
  border-radius: 0;

  &:first-child {
    border-radius: .5rem 0 0 .5rem;
  }

  &:last-child {
    border-radius: 0 0.5rem .5rem 0;
  }

  .close-icon {
    position: absolute;
    top: -.4rem;
    right: .2rem;
    border-radius: 50%;
    color: #f73b01;
  }

  &.v-0, &.v--1 {
    .icon .mdi-icon {
      fill: lighten($documentBackground, 15%);
    }
  }
}