@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.crypto-balances {
  margin: 0 auto 0 auto;
  max-width: 1150px;

  .total-value {
    h3 {
      margin-bottom: .25rem;
    }
    .counter {
      font-size: 2.5em;

      .counter-suffix, .counter-prefix, .magnitude {
        font-size: .8em;
        opacity: 50%;
      }

      .counter-suffix {
        margin-left: .15em;
      }
    }

    .value {
      gap: 1em;
      align-items: flex-end;
    }

    .usd-value {
      font-size: 1.75em;
      opacity: 50%;
      margin-bottom: .1em;
    }
  }

  .account {
    margin: 1rem 0;
    padding: 1rem;
    @media screen and (max-width: 800px) {
      border-radius: 0;
      margin-bottom: 0;
      box-shadow: none;
      &:nth-child(even) {
        background-color: rgba(black, 0%);
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: .5rem;

      .comment {
        font-size: .8em;
        opacity: 50%;
      }

      .value {
        margin-top: .25rem;
        font-size: 1.5rem;
        gap: .75rem;
        align-items: flex-end;

        .counter-prefix {
          font-size: .8em;
          opacity: 50%;
        }

        .counter-suffix {
          font-size: .8em;
          margin-left: .35em;
          opacity: 50%;
        }

        .usd-value {
          font-size: .75em;
          opacity: 50%;
          margin-bottom: .15em;
        }
      }

      .address {
        text-align: right;
        font-size: 1.15rem;

        .external-link {
          color: mix($goldenRed, $goldenYellow, 65%);

          &:hover {
            color: $goldenYellow;
            //color: $goldenRed;
          }
        }

        .debank-link {
          color: mix($goldenRed, $goldenYellow, 25%);
          font-size: .8em;
        }
      }

      .location {
        margin-top: .25rem;
        opacity: 75%;
        text-align: right;
        font-size: .8em;
      }
    }

    .asset-balances {
      margin-top: .75rem;
      gap: 1rem;
      flex-wrap: wrap;

      .asset-balance {
        color: white;
        background-color: rgba(black, 30%);
        padding: .75rem 1rem .5rem 1rem;
        border-radius: .25rem;
        transition: .35s ease-out;
        @media screen and (max-width: 800px) {
          flex: 1 1 8rem;
        }

        .quantity {
          margin-top: .15rem;
          font-size: 1.35rem;
          color: $goldenYellow;
        }

        .value {
          font-size: .85em;
        }

        .counter-suffix {
          font-size: .8em;
          margin-left: .25rem;
          opacity: 50%;
        }

        &:hover {
          background-color: rgba(black, 15%);
        }
      }
    }
  }
}