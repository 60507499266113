@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.analysis {

  margin: 1rem auto;
  max-width: 1150px;

  .kpis {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .duration {
      flex: 1 1 20rem;
      padding: 1rem 1.5rem;
      h2 {
        font-weight: normal;
        margin: 0 0 .5rem 0;
      }
      .kpi, .kpi-pct {
        h3 {
          font-size: .8em;
          font-weight: normal;
          opacity: .6;
          margin: 0;
        }
        .annualized {
          opacity: .5;
        }
        .values {
          display: flex;
          justify-content: space-between;
        }
        .counter-suffix {
          opacity: .5;
        }
      }
      .kpi-pct {
        margin-bottom: .5rem;
        font-size: .8em;
      }
    }
  }

}