@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.step-details-summary {
  padding: 0;
  overflow: hidden;
  flex: 1 1 10rem;
  .flex-vertical-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
    .mdi-icon {
      @include md-icon-size(1em);
      opacity: .5;
    }
  }
  .asset-header {
    opacity: .5;
    font-size: .8em;
  }
  .counter-suffix {
    opacity: .65;
  }
  .magnitude {
    opacity: .65;
  }
  tr {
    &:first-child td {
      padding-top: 1rem;
    }
    td:first-child {
      padding-left: 1rem;
    }
    td:last-child {
      padding-right: 1rem;
    }
    &:last-child td {
      //padding-bottom: 1rem;
    }
  }
}