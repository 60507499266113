@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-split-dialog {

  h2 {
    color: mix(white, $documentBackground, 50%);
    font-size: .9rem;
    font-weight: normal;
    .asset-name {
      display: block;
      color: white;
      font-size: 1.4rem;
    }
  }
  .button-container {
    text-align: right;
    margin: 1rem 0 .15rem 0;
  }
  @media screen and (max-width: 1150px) {
    input[type=number] {
      width: 12rem;
    }
  }
  .input-div {
    position: relative;
    margin-bottom: .5rem;
    input[type=number] {
      padding-left: 9.5rem;
    }
    .label {
      position: absolute;
      top: .5rem;
      left: .5rem;
      padding: .5rem .75rem;
      border-radius: .5rem;
      width: 8rem;
      text-align: center;
      background-color: rgba($documentBackground, 65%);
      color: $goldenYellow;
      font-weight: bold;
    }
  }
  .summary {
    display: inline-flex;
    border: 1px solid rgba(white, 20%);
    border-radius: .75rem;
    margin: 1rem 1rem;
    padding: 1rem 2.5rem;
    font-size: 2rem;
    .from {
      opacity: 75%;
    }
    .divider {
      font-weight: bold;
      font-size: 1.5rem;
    }
    .to {
      color: $goldenYellow;
    }
  }
}