@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-history {
  margin: .5rem auto;
  max-width: 1160px;

  &.loader-container {
    margin-top: 2rem;
    text-align: center;
    .loader {
      border-width: .35rem;
      margin: 0 5rem;
    }
  }

  .counter-suffix, .counter-prefix {
    opacity: 50%;
    font-size: .9em;
  }

  table {
    width: 100%;
    td {
      cursor: pointer;
    }
    th.shift-left {
      padding-right: 2.35rem;
    }
    .rate {
      color: #bbb;
    }
    .no-value {
      opacity: .5;
    }
    .date {
      .change-flavor-icon {
        margin-top: -.2rem;
        @include md-icon-size(1.2rem);
      }
    }
    .direction-icon {
      @include md-icon-size(.95em);
      margin-right: .2em;
      vertical-align: -.05em;
      &.is-negative {
        vertical-align: -.15em;
      }
    }
    .add-rate-button {
      .mdi-icon {
        @include md-icon-size(1.15em);
        &.stale {
          fill: #888;
        }
      }
      &:hover {
        .mdi-icon {
          fill: white;
        }
      }
    }
  }
}