.offloading-gauge {
  margin-top: 1.5rem;
  @media screen and (max-width: 1150px) {
    margin: 1rem .5rem .5rem .5rem;
  }
  padding: 0;
  overflow: hidden;
  h2 {
    margin: 1rem 1rem .25rem 1rem;
  }
  .totals {
    font-weight: bold;
  }
  table tr .flex-vertical-center {
    gap: 0;
    font-size: .8em;
    .mdi-icon {
      scale: 80%;
    }
  }
}