@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.card {
  background-color: rgba(black,25%);
  padding: 1rem 2rem 1rem 1rem;
  border-radius: .5rem;
  @include addInnerShadow(rgba(black,.35), 20px);

  &.is-positive {
    background-color: rgba(mix(black, $positive, 80%),25%);
    h3 {
      color: lighten($positive, 20%);
    }
  }
  &.is-negative {
    background-color: rgba(mix(black, $negative, 80%),25%);
    h3 {
      color: lighten($negative, 20%);
    }
  }
  &.is-error {
    background-color: rgba(mix(black, $red, 80%),25%);
    h3 {
      color: lighten($red, 20%);
    }
  }

  h3 {
    margin: 0;
    font-weight: normal;
    font-size: .8em;
    color: mix(white, $documentBackground, 60%);
  }
}