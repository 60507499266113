@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-picker {

  padding: 0;

  overflow: hidden;

  .selected-asset {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;

    .button {
      flex-direction: column;
      justify-content: center;
      background-color: darken($documentBackground, 8%);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:hover {
        background-color: darken($documentBackground, 15%);
      }
    }

    .asset-info {
      padding: 1rem;

      h3 {
        margin: 0 0 .5em 0;
      }

      .asset-name {
        display: flex;
        gap: .25rem .5rem;
        flex-wrap: wrap;
        font-size: 1.5rem;
        align-items: center;

        .name {
          word-break: break-word;
        }

        .code {
          font-size: .8em;
          opacity: .5;
        }
      }
    }
  }

  .asset-options {

    &.is-scrollable {
      .assets {
        max-height: 40vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .filters {

      justify-content: space-between;

      .text-filter {
        position: relative;
        margin-right: 1rem;

        input {
          padding: .75rem 2.75rem .75rem 2.75rem;
        }

        .search-icon {
          position: absolute;
          left: .75rem;
          top: .75rem;
          opacity: 50%;
        }

        .reset-button {
          position: absolute;
          right: .95rem;
          top: .8rem;
          border-radius: 50%;
          height: 1.38rem;
          width: 1.4rem;
          padding: 0;
          border: 0;
          background-color: darken($red, 20%);
          transition: .25s ease-out;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          @include addGlow(black);

          .mdi-icon {
            @include md-icon-size(1.15em);
          }

          &:hover {
            background-color: darken($red, 10%);
            //@include addGlow(#9f6c32);
            .mdi-icon {
              color: white;
            }
          }
        }
      }

      .asset-group-filter {
        padding: 1rem;
        display: flex;
        gap: .75rem;
        flex-wrap: wrap;

        button {
          @media screen and (max-width: 800px) {
            flex: 1 1 5rem;
            &:first-child {
              display: none;
            }
          }
          cursor: pointer;
          background: rgba(black, 25%);
          border: 0;
          border-radius: .5rem;
          padding: .85rem 1.25rem .85rem 1rem;
          color: #888;
          transition: .25s ease-out;

          .asset-icon {
            margin-top: -.1em;
            @include md-icon-size(1.2em);
          }

          &:hover {
            color: #ddd;
            background: rgba(black, 50%);
          }
          &.is-selected {
            color: $orange;
          }
        }
      }

    }

    .no-hits {
      padding: .75rem 1rem 2rem 1rem;
      text-align: center;
      opacity: 75%;
    }

    .asset-option {
      &:last-child button {
        padding-bottom: 1.25rem;
      }

      button {

        color: white;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: .25rem .5rem;
        cursor: pointer;

        width: 100%;
        text-align: left;
        padding: .75rem 1rem;
        border-radius: 0;
        background: transparent;

        .asset-icon, {
          @include md-icon-size(1.2em);
        }

        .std-icon {
          @include md-icon-size(1.1em);
          scale: 1.5;
          margin-top: -.15em;
        }

        &:hover {
          scale: 1;
          background: rgba(black, .35);
        }

        .code {
          font-size: .9em;
          opacity: .5;
        }
      }

      &:nth-child(even) {
        button {
          background: rgba(black, .25);
        }
      }

      button {
        transition: .25s ease-out;

        &:hover {
          background: rgba(black, .35);
        }
      }
    }


  }
}