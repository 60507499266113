@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.step-confirmation {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem 0;
  .inputs {
    flex-basis: 36rem;
    textarea {
      width: 100%;
      height: 14rem;
    }
  }
}