@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.login {

  max-width: 300px;
  margin: 0 auto;

  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  gap: .1rem;
  align-items: flex-start;
  .input-row {
    margin-bottom: .5rem;
  }
  .button-row {
    margin-top: .65rem;
    display: flex;
    width: calc(100% - 1.5rem);
    justify-content: flex-end;
    .button {
      background-color: darken($documentBackground, 8%);
      padding: .65rem 1rem .65rem 1.75rem !important;
      &:hover {
        background-color: darken($documentBackground, 15%);
      }
    }
  }
}