$documentBackground: #282c34; // Original
$documentBackground: #20252f; // Gray
//$documentBackground: #1e2138; // Makerburn tribute
//$documentBackground: #17182d; // Makerburn tribute 2
//$documentBackground: #14162d; // Makerburn actual
//$documentBackground: #1a2d17; // Forest
//$documentBackground: #211815; // Wisdom
//$documentBackground: #2d172d; // Royal
//$documentBackground: #2d2717; // YellowBrown
//$documentBackground: #2d1717; // Maroon
//$documentBackground: #172d28; // Tropic
//$documentBackground: #192531; // Navy

$yellow: #ffdc01;
$orange:#fdac01;
$red: #f73b01;

$goldenYellow: mix($yellow, $orange, 35%);
$goldenRed: mix($orange, $red, 35%);
$goldenOrange: mix($goldenRed, $goldenYellow, 65%);

$positive: #60ec7c;
$positiveL2: $goldenYellow;
$positiveL3: $goldenOrange;

$negative: #9469c0;
$negativeL2: mix($negative, $red, 85%);
$negativeL3: mix($negative, $red, 35%);
