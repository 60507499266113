@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";
.breadcrumbs {
  font-size: 1.15rem;
  background: linear-gradient(to bottom, rgba(black,30%), rgba(black,15%));
  @include addInnerShadow();
  padding: 1rem 1.5rem;
  border-radius: .75rem;
  @media screen and (max-width: 800px) {
    background: linear-gradient(to bottom, rgba(black,35%), rgba(black,0));
    border-radius: 0;
    padding: 1rem;
  }
  h3 {
    margin-top: 0;
    font-weight: normal;
  }
  .filter-header {
    max-width: 50vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .breadcrumb-separator {
    opacity: .35;
    margin: .05em .35rem 0 .35rem;
    @include md-icon-size(1em);
    scale: 130%;
  }
  .asset-name {
    display: flex;
    gap: .25rem;
    .name {
      max-width: 37vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .code {
      margin-left: .25rem;
      opacity: 50%;
    }
  }
  .filter-preset {
    display: flex;
    gap: .3em;
    span {
      @media screen and (max-width: 500px) {
        display: none;
        &:first-child {
          display: inline-block;
        }
      }
    }
  }
  .links {
    display: flex;
    //gap: 2rem;
    @media screen and (max-width: 800px) {
      //gap: 1.25rem;
    }
    align-items: center;
    a {
      display: flex;
      transition: .25s ease-out;
      border-radius: .5rem;
      background-color: transparent;
      color: $goldenYellow;
      text-decoration: none;
      font-weight: normal;
      align-items: center;
      gap: .5rem;
      &:first-of-type {
        @media screen and (max-width: 800px) {
        }
      }
      &:hover, &:active {
        color: white;
      }
      &.is-active {
        color: white;
        text-shadow: 0 0 4px $goldenOrange;
      }
      @media screen and (max-width: 500px){
        .text {
          display: none;
        }
      }
      &.reset-asset-selection {
        background-color: lighten($documentBackground, 2.5%);
        border-radius: 50%;
        scale: 80%;
        margin-left: .35em;
        .mdi-icon {
          scale: 75%;
        }
        &:hover {
          background-color: lighten($documentBackground, 10%);
        }
      }
    }
  }
  .active-asset {
    margin-left: auto;
    max-width: 48vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.3em;
    color: white;
    @media screen and (min-width: 500px){
      font-size: 1.3em;
      margin-bottom: -.25em;
    }
  }
}