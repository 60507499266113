@import "src/modules/util/scss/colors";
$bright-color-purple: #ff75ce;
$bright-color-purple-2: #bfa3ff;

.loader-horizontal {

  position: relative;
  height: .2rem;
  margin-bottom: 1rem;
  background-color: darken($documentBackground, 4%);

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    background-image: linear-gradient(0.45turn, $bright-color-purple, $bright-color-purple-2);
    box-shadow: 0 0 10px $bright-color-purple;
    width: 3rem;
    height: .2rem;
    border-radius: .25rem;

    transition: all .5s ease-in-out;
    animation: ani 1.25s infinite;
  }

}

@keyframes ani {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 3rem);
  }
  100% {
    left: 0;
  }
}