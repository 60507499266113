@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.transaction-wizard {
  margin: .5rem auto .5rem auto;
  max-width: 1150px;
  @media screen and (max-width: 1150px) {
    margin: 1rem;
  }

  .step-type {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .button {
      justify-content: center;
      flex: 1 1 10rem;
      padding: 2em 0;
      font-size: 1.1em;
      border-radius: .5em;
      font-weight: normal;
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5em;
      }
      &.is-gas {
        .button-content {
          gap: .25em;
        }
      }
      .mdi-icon {
        @include md-icon-size(1.5em);
        fill: url(#golden-gradient) $goldenYellow;
        margin-top: -.1em;
      }
      @media screen and (max-width: 500px) {
        &:first-child {
          flex-basis: 100%;
        }
      }
    }
    margin-bottom: 5em;
  }

}