@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.tax-report {
  margin: 0 auto 0 auto;
  max-width: 1150px;
  .options {
    margin-top: 1rem;
    flex-wrap: wrap;
    .card {
      padding-top: 1.3rem;
    }
  }
  table {
    width: 100%;
    .year {
      font-size: 2rem;
      padding-top: 1rem;
    }
    .year-profit {
      font-sizE: 1.2em;
      padding-top: .25em;
    }
    .strategy {
      color: $goldenYellow;
      font-size: .8em;
      opacity: 80%;
    }
    th {
      text-align: left;
      font-weight: normal;
      opacity: 50%;
      font-size: .7em;
    }
    .counter-suffix {
      font-size: .8em;
      opacity: 80%;
      margin-left: .35em;
    }
  }
}