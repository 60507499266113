@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.plans {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  padding: .5rem;
  backdrop-filter: blur(5px);
  border-radius: .35rem;
  width: 15rem;
  background: darken($documentBackground, 3.5%);
  background: linear-gradient(to left, darken($documentBackground, 3.5%), darken($documentBackground, 7%));
  @include addShadow();
  @media screen and (max-width: 1600px) {
    display: none;
  }

  h3 {
    margin: 0 0 -.25rem .5rem;
    font-weight: normal;
    justify-content: space-between;
    color: mix(white, $documentBackground, 70%);
    font-size: 1em;

    .mdi-icon {
      color: $goldenOrange;
      scale: 75%;
    }
  }

  ul {
    padding: 0;
    margin: 0 0 .5rem 0;
    list-style: none;

    li {
      margin: 0;

      button {
        margin-top: .5rem;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        background: darken($documentBackground, 1%);
        border-radius: .25rem;
        color: white;
        border: 0;
        gap: .25rem .5rem;
        transition: .2s ease-out;
        padding: .5rem .5rem .35rem .5rem;

        .asset-name {
          max-width: 13rem;
          flex-basis: 20rem;
          text-align: left;
          font-size: .70em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          opacity: .65;
        }

        .date {
          transition: inherit;
          color: $goldenYellow;
        }

        .amount {

          .prefix {
            opacity: .5;
          }
        }

        &:hover {
          color: $goldenYellow;
          background: lighten($documentBackground, 0.5%);
          transform: translateX(.25em);
          @include addShadow();
          .date {
            color: $goldenOrange;
          }
        }
      }
    }
  }

  .bulk-import-link {
    font-size: .8rem;
    padding-left: .5rem;
  }
}