.dashboard-addendum {
  position: relative;
  margin: 0 auto 0 auto;
  max-width: 1120px;
  display: flex;
  .group-assets-toggle {
    margin-left: -.5rem;
    margin-top: .2rem;
    scale: 70%;
    .switch {
      scale: 75%;
    }
  }

}