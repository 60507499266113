@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.asset-stats {
  margin: 0 auto;
  max-width: 1120px;
  @media screen and (max-width: 500px){
    margin-top: .5rem;
  }
  .ath-barometers {
    margin: 3rem 0 2rem 0;
    display: flex;
    gap: 2rem 2rem;
    flex-wrap: wrap;
    &.reduced-gap {
      gap: 2rem 1rem;
      .ath-barometer {
        &.has-change {
          flex: 1 1 calc(15rem + var(--padding));
        }
      }
    }
    @media screen and (max-width: 500px){
      margin-top: .75rem;
      margin-bottom: 1rem;
      gap: .5rem;
      &.reduced-gap {
        gap: .5rem;
      }
    }
  }
}