@import "scss/colors";

.counter {

  display: inline-block;
  position: relative;
  border-radius: .2em;

  span {
    display: inline !important;
  }

  &.bold {
    font-weight: bold;
  }

  transition: color .5s ease-in, background-color .5s ease-in, text-shadow .5s ease-in;
  &.changing {
    &.positive {
      color: $positive !important;
      //background-color: rgba($positive,.1) !important;
      text-shadow: 0 0 10px rgba($positive,1);
      //text-shadow: 0 0 10px $positive;
    }
    &.negative {
      color: $negative !important;
      //background-color: rgba($negative,.1) !important;
      text-shadow: 0 0 10px rgba($negative,1);
      //text-shadow: 0 0 10px $negative;
    }
  }
  .counter-amount {
    position: relative;
  }
  &.stable {
  }

}