@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

@font-face {
  font-family: OpenSans;
  src: url(../public/OpenSans-VariableFont_wdth,wght.ttf);
}

@font-face {
  font-family: Ubuntu;
  src: url(../public/Ubuntu-Regular.ttf);
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

body {
  position: relative;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 8rem;
  font-family: Ubuntu;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $documentBackground;
  color: white;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &:before {
    background: linear-gradient(to bottom, darken($documentBackground, 9%), $documentBackground) $documentBackground;
    content: "";
    display: block;
    height: 700px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: white;
  text-decoration: none;
  transition: .25s ease-out;

  &:hover, &:active {
    color: white;
  }
}

input {
  padding: 1rem 1.25rem;
  font-size: 1.2rem;
  border-radius: .5rem;
  background: linear-gradient(to bottom, rgba(black, .35), rgba(black, .10));
  color: white;
  border: 0;
  @include addInnerShadow();

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
    //background-image: url(#golden-gradient) $goldenYellow;
  }

  &:disabled {
    color: gray;
    background: linear-gradient(to bottom, rgba(gray, .05), rgba(gray, .20));
  }
}

textarea {
  padding: 1rem 1.25rem;
  font-size: 1.2rem;
  border-radius: .5rem;
  background: linear-gradient(to bottom, rgba(black, .35), rgba(black, .10));
  color: white;
  border: 0;
}

.numeric {
  text-align: right !important;
}
.center {
  text-align: center !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */

}

.hide-portrait {
  @media screen and (max-width: 800px) {
    display: none !important;
  }
}

.show-portrait {
  @media screen and (min-width: 801px) {
    display: none !important;
  }
}

.hide-desktop {
  @media screen and (min-width: 801px) {
    display: none !important;
  }
}


.small {
  margin-top: .25em;
  font-size: .8em;
  opacity: 80%;
}
.weak {
  opacity: 25%;
}
.dull {
  opacity: 50%;
}

.is-positive {
  color: darken($positive, 0%);
}

.is-positive-l2 {
  color: $positiveL2;
}

.is-positive-l3 {
  animation-name: blinking-gold;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  .counter {
    transition: none;
  }
}

.is-negative {
  color: lighten($negative, 10%);
}

.is-negative-l2 {
  color: $negativeL2;
}

.is-negative-l3 {
  color: $negativeL3;
}

.is-weak {
  opacity: 65%;
}
.is-zero {
  color: #888;
}

.is-gas {
  color: $goldenRed;
}

.is-golden {
  color: $goldenYellow;
}

.is-error {
  color: lighten($red, 5%);
}

#rainbow-background {
  background: linear-gradient(217deg, rgba(255, 0, 0, .8), rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, rgba(0, 255, 0, .8), rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, rgba(0, 0, 255, .8), rgba(0, 0, 255, 0) 70.71%);
}

.std-icon {
  @include md-icon-size(1em);
  vertical-align: -.125em;
  margin-left: .25em;
  fill: url(#golden-gradient) $goldenYellow;

  &.is-negative {
    fill: $negative;
  }
}

.flex-vertical-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .25rem .5rem;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}

.page {
  margin: .5rem auto .5rem auto;
  max-width: 1150px;
  @media screen and (max-width: 1150px) {
    margin: 1rem;
  }
}