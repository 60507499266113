@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.internal-link {
  transition: .25s ease-out;
  color: $goldenYellow;

  > .flex-vertical-center {
    display: inline-flex;
  }

  &.has-icon {
    .flex-vertical-center {
      gap: .25rem;
    }

    .mdi-icon {
      transition: .25s ease-out;
      fill: url(#golden-gradient) $goldenYellow;
      @include md-icon-size(1.1em);
    }
  }

  &:hover, &.active {
    color: $goldenOrange;
  }

  &.button {
    color: white;
    &.has-icon {
      .mdi-icon {
        margin: .14em .24em 0 0;
        fill: url(#golden-gradient) $goldenYellow;
      }
    }
  }

}