@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.rate-trajectory {
  //height: 7.5rem;
  .kpi {
    overflow: hidden;
    .trajectory-bar {
      position: absolute;
      bottom: .75rem;
      right: .75rem;
      width: 1.15rem;
      height: calc(100% - 1.5rem);
      opacity: 75%;
      &:after {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        border-radius: .18rem;
        height: var(--height);
        bottom: 0;
        @include addShadow();
      }
      &.is-positive:after {
        background: linear-gradient(to top, mix($documentBackground, $positive, 85%), mix($documentBackground, $positive, 15%));
      }
      &.is-negative:after {
        background: linear-gradient(to top, mix($documentBackground, $negative, 85%), mix($documentBackground, $negative, 15%));
      }
    }
  }
}