@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.chart {
  position: relative;
  margin: -.5rem auto 0 auto;
  max-width: 1400px;
  //background-color: #090a0c;
  padding: 0 0 .5rem 0;
  border-radius: .5rem;
  //max-height: 700px;
  .chat-options {
    margin: 0 auto 1rem auto;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
      margin-bottom: 0;
    }
  }
  .loader-wrapper {
    width: 1400px;
    height: calc(100vw / 2);
    max-height: 43vh;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-circle {
      height: 8rem;
      width: 8rem;
      border-width: .5rem;
      box-shadow: inset 0 0 6px black, 0 0 6px 6px rgba(0,0,0,.25);
      @media screen and (max-width: 500px) {
        height: 5rem;
        width: 5rem;
        border-width: .25rem;
      }
    }
  }
  canvas {
    width: 1300px;
    max-height: 43vh;
    transition: .25s ease-out;
    &.transparent {
      opacity: 0;
    }
  }
  .no-data {
    position: relative;
    margin-bottom: -.7rem;
    &-desc {
      position: absolute;
      top: 0;
      left:0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: .25rem;
      .mdi-icon {
        @include md-icon-size(2rem);
        color: mix($goldenYellow, $goldenOrange, 50%);
      }
      .text {
        opacity: 50%;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0 0 .5rem 0;
  }
}