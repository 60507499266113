@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.diff-tracker {
  position: absolute;
  bottom: .65rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: translateX(.5rem);
  transition: .25s ease-out;
  pointer-events: none;

  .counter-suffix, .counter-prefix, .magnitude {
    opacity: .65;
    font-size: .85em;
  }

  &.is-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .content {
    background: linear-gradient(to bottom, rgba(black, 35%), rgba(black, 15%));
    padding: .75rem 1.6rem .75rem 1.75rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    //@include addShadow();
    .timespan {
      color: mix(white, $documentBackground, 50%);

      b {
        color: white;
        margin-right: .2rem;
        font-size: 1.15em;
      }
    }

    .delta {
      font-size: 1.3em;
    }

    .delta-pct {
      margin-left: .5rem;
      font-size: 1.25em;

      .suffix {
        opacity: .65;
        font-size: .85em;
      }
    }

    .direction-icon {
      margin: 0 .25rem .1rem .5rem;
      @include md-icon-size(1.05em);
    }

    &:after {
      position: absolute;
      left: -.25rem;
      top: .98rem;
      content: '';
      border-radius: 50%;
      height: 10px;
      width: 10px;
      border: 2px solid rgba(white, 50%);
      scale: 0;
      opacity: 0;
      transition: .25s ease-out;
    }
  }
  &.is-visible {
    .content:after {
      //scale: 100%;
      //opacity: 100%;
    }
  }
}