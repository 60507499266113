@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.dashboard {
  position: relative;
  margin: -2rem auto 0 auto;
  @media screen and (max-width: 1290px){
    margin-top: 0;
  }
  padding: 0 .5rem;
  max-width: 1164px;
  border-radius: .5rem;
  @media screen and (max-width: 1200px){
    border-radius: 0;
    padding: 0;
    background: linear-gradient(to bottom, rgba(black,7%), rgba(black,30%));
    margin-top: -1.25rem;
    @include addShadow();
  }
}