@import "src/modules/util/scss/colors";
@import "src/modules/util/scss/mixins";

.transaction-page {
  margin: .5rem auto;
  max-width: 1080px;
  @media screen and (max-width: 800px) {
    margin: -.5rem .5rem;
  }

  h2 {
    font-weight: normal;
    font-size: 1.2rem;
    margin: .75rem 0 .75rem .5rem;

    .tx-id {
      opacity: .5;
    }

    a {
      color: white;
    }

    @media screen and (max-width: 800px) {
      margin-bottom: .5rem;
    }

    .mdi-icon {
      @include md-icon-size(1.2em);
      vertical-align: -.325em;
      opacity: .25;
      margin-right: -.1em;
    }
  }


  .kpis {
    display: flex;
    gap: 2rem;
    margin-bottom: 1em;
    align-items: center;
    flex-wrap: wrap;

    .kpi {

      &.type {
        margin-left: 1rem;
      }

      h3 {
        margin: 0;
        font-size: .65rem;
        opacity: 60%;
        font-weight: normal;
      }

      &-value {
        margin-top: .25em;
        font-size: 1.25em;
        display: flex;
        align-items: center;
        gap: .35em;
        white-space: nowrap;

        .direction-icon {
          @include md-icon-size(.95em);
          margin-right: -.1em;
        }

        .counter-suffix {
          opacity: .5;
        }

        .secondary {
          font-size: .8em;
        }
      }

      &.large-content {
        .mdi-icon {
          @include md-icon-size(1.15em);
          margin-left: .5em;
          margin-right: -.25em;
          &.is-company {
            margin-left: .25em;
          }
        }
      }
    }
  }


  .connected-transaction {
    margin-bottom: 1rem;
    h4 {
      font-size: .8rem;
      font-weight: normal;
      margin: 0 0 .5rem 0;
      opacity: 50%;
    }
    .quantity {
      opacity: 75%;
      margin-left: .75rem;
      .code {
        opacity: 60%;
        margin: 0 .25rem;
      }
    }
    .internal-link {
      display: inline-block;
      margin-top: .5rem;
      font-size: .8rem;
    }
  }

  .inputs {
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 800px) {
      gap: .5rem;
    }
    flex-wrap: wrap;
    h3 {
      font-weight: normal;
      font-size: .8em;
      opacity: .65;
      margin: 0 0 .5rem;
    }
    .input-container {
      flex: 1 1 10rem;
      @media screen and (max-width: 800px) {
        flex: 0 1 30rem;
      }
      .content {
        position: relative;
        width: 100%;
        input {
          width: 100%;
        }
        .denominator {
          position: absolute;
          top: .6em;
          right: .6em;
          padding: .5em;
          backdrop-filter: blur(5px);
          background-color: rgba(#444, .25);
          border-radius: .5em;
          color: #aaa;
        }
      }
    }
  }

  .form {
    background: rgba(black, .15);
    padding: 1rem;
    border-radius: .5rem;
    @include addInnerShadow();
  }

  textarea {
    margin-top: 1rem;
    width: 100%;
    height: var(--height);
  }

  .footer {
    gap: 1rem;
    margin-top: 1rem;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 800px) {
      padding: 0 .5rem;
    }
    .buttons {
      flex: 1 1 10rem;
      gap: 1rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}